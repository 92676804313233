import { isGroupsCategory } from '@orus.eu/activity'
import { parseAmount } from '@orus.eu/amount'
import type { SubscriptionInjectableParams } from '@orus.eu/backend/src/services/subscription/subscription-inputs-injection-service'
import {
  inferCompanyIdNumberFromSiret,
  parseCifValue,
  parseNifValue,
  parseSirenValue,
} from '@orus.eu/company-id-number'
import { allProducts, isDiscountCode, productSelectedDimension, type EmbeddingPartner } from '@orus.eu/dimensions'
import { failure, success, type Result } from '@orus.eu/result'
import { parseSiret } from '@orus.eu/siret'
import type { SearchParams } from '../routes/__root'
import { validatePhoneNumber } from './validation'

export function getSubscriptionInjectableParamsFromURLSearchParams(
  searchParams: SearchParams,
): Result<SubscriptionInjectableParams, { type: 'invalid-params' }> {
  const subscriptionInjectableParams: Mutable<SubscriptionInjectableParams> = {}

  const embeddingPartnerId = searchParams['embeddingPartner']
  if (embeddingPartnerId) {
    const embeddingPartner: EmbeddingPartner = { id: embeddingPartnerId }
    subscriptionInjectableParams.embeddingPartner = embeddingPartner
  }

  const activity = searchParams['activity']
  if (activity) {
    subscriptionInjectableParams.activity = activity
  } else {
    const category = searchParams['category']
    if (category) {
      if (isGroupsCategory(category)) {
        subscriptionInjectableParams.category = category
      } else {
        return failure({ type: 'invalid-params' })
      }
    }
  }

  const firstName = searchParams['firstname']
  if (firstName) {
    subscriptionInjectableParams.firstName = firstName
  }

  const lastName = searchParams['lastname']
  if (lastName) {
    subscriptionInjectableParams.lastName = lastName
  }

  const siret = searchParams['siret']
  const parsedSiret = siret ? parseSiret(siret) : null
  if (parsedSiret) {
    subscriptionInjectableParams.siretData = { type: 'provided', siret: parsedSiret }
    subscriptionInjectableParams.companyIdNumber = inferCompanyIdNumberFromSiret(parsedSiret)
  } else {
    const siren = searchParams['siren']
    const parsedSiren = siren ? parseSirenValue(siren) : null
    if (parsedSiren) {
      subscriptionInjectableParams.companyIdNumber = { type: 'siren', siren: parsedSiren }
    }
    const cif = searchParams['cif']
    const parsedCif = cif ? parseCifValue(cif) : null
    if (parsedCif) {
      subscriptionInjectableParams.companyIdNumber = { type: 'cif', cif: parsedCif }
    }
    const nif = searchParams['nif']
    const parsedNif = nif ? parseNifValue(nif) : null
    if (parsedNif) {
      subscriptionInjectableParams.companyIdNumber = { type: 'nif', nif: parsedNif }
    }
  }

  const email = searchParams['email']
  if (email) {
    subscriptionInjectableParams.email = email
  }

  const phone = searchParams['phone']
  if (phone && validatePhoneNumber(phone).ok) {
    subscriptionInjectableParams.phone = phone
  }

  const revenue = searchParams['revenue']
  if (revenue) {
    const revenueAmount = parseAmount(revenue)
    if (revenueAmount) {
      subscriptionInjectableParams.estimatedRevenue = revenueAmount
    }
  }

  const discount = searchParams['discount']
  if (discount && isDiscountCode(discount)) {
    subscriptionInjectableParams.discountCode = discount
  }

  const operatingZone = searchParams['operatingZone']
  if (operatingZone) {
    subscriptionInjectableParams.operatingZone = operatingZone
  }

  for (const product of allProducts) {
    const productSelected = productSelectedDimension[product].name
    subscriptionInjectableParams[productSelected] = searchParams[productSelected]
  }

  return success(subscriptionInjectableParams)
}

type Mutable<T> = {
  -readonly [P in keyof T]: T[P]
}
